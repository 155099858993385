<template>
  <div v-element-sizes="{ id: 'window' }" class="layout-default">
    <SeoTemplatePreset>
      <NavigationMenu />
      <main>
        <slot />
      </main>

      <template
        v-if="
          !route?.name?.includes('lets-talk') &&
          !route?.name?.includes('contacts')
        "
      >
        <FooterMain />
        <FooterMedical />
      </template>

      <WidgetScrollTop />
      <ListingProjectBlockFullscreenOverlay />
    </SeoTemplatePreset>
  </div>
</template>

<script setup>
const route = useRoute()
const fromError = setFromError()
/*watch(
  () => fromError.value,
  (fromError) => {
    if (
      fromError
    ) {
      reloadNuxtApp() // è brutterrimo, bisogna trovare un fix sensato e più pulito
      
    } else {
      return
    }
  }
)*/
</script>
